.centered {
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .centered-card {
    width: 100%;
  }
}

li {
  list-style: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#react-app {
  position: relative;
  overflow: hidden;
}
