.centered {
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .centered-card {
    width: 100%;
  }
}

.middle-content {
  margin-left: 0;
  margin-right: 0;
  display: inline-block;
  position: relative;
  width: 100%;
  top: 76px;
  bottom: 64px;
  overflow-y: auto;
}

.Select-menu-outer {
  position: relative !important;
  height: 160px;
}

input {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

label {
  margin-left: 2px;
}

.ReactTable input {
  box-sizing: content-box !important;
}

.MuiExpansionPanelSummary-content {
  margin: 0 !important;
}

.mce-widget {
  display: none;
}

.tox {
  display: none;
}

li {
  list-style: none;
}

.root {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

input:disabled {
  color: black;
}

.footer {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #ffffff;
  box-shadow: 10px 0 30px -12px rgba(0, 0, 0, 0.42), 4px 0 25px 0px rgba(0, 0, 0, 0.12),
    8px 0 10px -5px rgba(0, 0, 0, 0.2);
}

.footer .push {
  height: 53px;
}

@media print {
  /*
    body {
        counter-reset: page 0;
    }

    .print-footer-page::after {
        counter-increment: page;
        content: counter(page);
    }
    */

  p,
  span {
    font-size: 0.8em !important;
  }

  @page {
    margin: 8px;
  }

  table {
    page-break-after: always;
  }

  footer {
    position: fixed;
    bottom: 0;
  }

}

.break-line {

  position: relative;

}

.break-line::after {

  position: absolute;
  left: 0;
  top: 68%;
  content: "";
  height: 1px;
  background: black;
  width: 100%;
  display: block;

}
